import config from '../config/config';
import React,{createContext, useState, useContext} from 'react';
import { NotificationManager } from 'react-notifications';
import { authFetchHook } from './AuthenticatedFetch';
import { useSelector } from 'react-redux';

const workOrderTemplate = {  
    wo: null,
    woItems: null,
    clearWorkOrder: () => {},
  createWorkOrder: async() =>{},
  getWorkOrder: async() =>{},
  getWorkOrderItems: async() => {},
  updateWorkOrder: async() => {},
  setWo: ()=> {},
  setWoItems: () => {},
}

export const WorkOrderContext = createContext(workOrderTemplate)

export const useWorkOrderContext = () => useContext(WorkOrderContext)

export const WorkOrderProvider =({children}) => {

    const user = useSelector(state => state.auth)
    
    const [wo,setWo] = useState(null)
    const [woItems,setWoItems] = useState(null)
     
     const createWorkOrder = async(wo, itms) => {

        console.log('work odere is', wo)
        const resp = await authFetchHook(user.authUser, `${config.apiPath}/workorder`, "POST", JSON.stringify(wo))  
        if(resp) {
            
            if(itms) {
               const itmRsp = await authFetchHook(user.authUser, `${config.apiPath}/workorder/addItems/`+resp.id, "PUT", JSON.stringify(itms))
                if(itmRsp === 'success') {
                    NotificationManager.success("WorkOrder Saved", "Success", 2000)
                }
            } else {
                NotificationManager.success("WorkOrder Saved", "Success", 2000)
            }
           
        } else {
            NotificationManager.error("Issue Saving work order", "Error", 2000)
        }
     }

     const clearWorkOrder = () => {
        setWo(null);
        setWoItems(null);
     }



     const getWorkOrder = async(quoteId, currentQuote) => {
        clearWorkOrder()
        await authFetchHook(user.authUser, `${config.apiPath}/workorder/`+quoteId, 'GET').then( async json => {
            console.log('workod is',json);
            if (json && json.length !== 0) {
                setWo(json)
                await getWorkOrderItems(json.id, currentQuote)
            }else {
                setWo({
                    QuoteId: currentQuote.id,
                    jobname: currentQuote.EndUser ? currentQuote.EndUser.name : ''
                    , ponumb: currentQuote.ponumb
                    , address: currentQuote.JobSite ? currentQuote.JobSite.address : ''
                    , dealer: currentQuote.Customer ? currentQuote.Customer.name : ''
                    , salesrep: currentQuote.SalesPerson ?  currentQuote.SalesPerson.firstName + ' ' + currentQuote.SalesPerson.lastName  : ''})
                    await getWorkOrderItems(null, currentQuote)
            } 
           
        })
    
     }

     const getWorkOrderItems = async(workOrderId,currentQuote) => {
        var rItems = []
        if(workOrderId) {
        await authFetchHook(user.authUser, `${config.apiPath}/workorder/workorderItems/`+workOrderId, 'GET')
        .then(json => { 
            console.log('resp items is ', json)
           
            if(json.length > 0) {
           
            json.forEach(itm => {
                rItems.push(itm.itemdescription)
            })
            }else {
                console.log('IM HERE')
                if(currentQuote && currentQuote.QuoteItems) {
                    currentQuote.QuoteItems.forEach(itm => {
                        console.log('item is', itm)
                        rItems.push(itm.itemDescription)
                    })
                }
            }
           
        })
        }else {
            if(currentQuote && currentQuote.QuoteItems) {
                currentQuote.QuoteItems.forEach(itm => {
                    console.log('item is', itm)
                    rItems.push(itm.itemDescription)
                })
            }
        }
        setWoItems(rItems)
     }

     const updateWorkOrder = async(workOrder, itms) => {
        console.log('items are ',itms)
        await authFetchHook(user.authUser, `${config.apiPath}/workorder/`+workOrder.id, "PUT", JSON.stringify(workOrder)).then(async resp => {
            if(itms) {
                const itmRsp = await authFetchHook(user.authUser, `${config.apiPath}/workorder/addItems/`+workOrder.id, "PUT", JSON.stringify(itms))
                console.log(itmRsp)
                if(itmRsp === 'success') {
                
                     NotificationManager.success("WorkOrder Saved", "Success", 2000)
                 }
             } else {
                 NotificationManager.success("WorkOrder Saved", "Success", 2000)
             }
        })
     }

    return (<WorkOrderContext.Provider value={{wo,woItems,createWorkOrder,setWo,getWorkOrder, getWorkOrderItems, updateWorkOrder, clearWorkOrder,setWoItems}}>
     {children}
    </WorkOrderContext.Provider>)
}